import * as React from "react";

const Header = ({ agendaItems, dienstleistungenItems }) => (
  <header>
    <nav className="navigation">
      <ul className="mainNav-list">
        <li className="mainNav-list-item">
          <a className="buttonNav Home" href="#start">
            Home
          </a>
        </li>

        {agendaItems.length > 0 ? (
          <li className="mainNav-list-item">
            <a className="buttonNav agenda" href="#agenda">
              Agenda
            </a>
          </li>
        ) : (
          ""
        )}

        <li className="mainNav-list-item">
          <a className="buttonNav blog" href="#blog">
            Blog
          </a>
        </li>

        <li className="mainNav-list-item">
          <a className="buttonNav texte" href="#texte">
            Texte
          </a>
        </li>

        {dienstleistungenItems.length > 0 ? (
          <li className="mainNav-list-item">
            <a className="buttonNav dienstleistungen" href="#dienstleistungen">
              Dienstleistungen
            </a>
          </li>
        ) : (
          ""
        )}

        <li className="mainNav-list-item">
          <a className="buttonNav kontakt" href="#kontakt">
            Kontakt
          </a>
        </li>
      </ul>
    </nav>
  </header>
);

export default Header;
