import React, { useState } from "react";

const Kontakt = () => {
  const [isSent, setIsSent] = useState(false);

  const formSubmit = (e) => {
    e.preventDefault();
    const form = document.querySelector(".mitteilungsformular");
    const formData = new URLSearchParams(new FormData(form));
    fetch("https://formmail.goeast.ch/codezone/goeastform.cfm", {
      method: "post",
      body: formData,
    });
    setIsSent(true);
  };

  return (
    <section id="kontakt" className="content">
      {isSent ? (
        <div>Vielen Dank für Ihre Mitteilung.</div>
      ) : (
        <>
          <h2>Kontakt</h2>
          <p>
            Ich will dem Dichter Stauffer eine{" "}
            <a href="mailto:buero@dichterstauffer.ch">Email</a> schicken.
          </p>
          <br />
          <br />
          <p className="fliesstext">
            Michael Stauffer
            <br />
            Dichterstauffer
            <br />
            Route du Büttenberg 32A
            <br />
            2504 Bienne
            <br />
            CH-Suisse
            <br />
            <a href="mailto:buero@dichterstauffer.ch">
              buero@dichterstauffer.ch
            </a>
            <br />
            CHE-348.742.337
          </p>
        </>
      )}
    </section>
  );
};

export default Kontakt;
