import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import sampleSize from "lodash/sampleSize";
import shuffle from "lodash/shuffle";

import Intermezzo from "../components/Intermezzo";
import Blog from "../components/Blog";
import Texte from "../components/Texte";

import DefaultLayout from "../layouts/default";
import Kontakt from "../components/Kontakt";
import Agenda from "../components/Agenda";
import Dienstleistungen from "../components/Dienstleistungen";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        intermezzoBilder: entry(type: "intermezzoBilder") {
          ... on CMS_main_intermezzoBilder_Entry {
            intermezzoBilder {
              url
            }
          }
        }
        agendaItems: entries(type: "agendaItem", orderBy: "datumUndUhrzeit") {
          title
          slug
          id
          ... on CMS_main_agendaItem_Entry {
            datumUndUhrzeit
            lokal
            ort
            linkURL
            linktext
            mitwirkende
            inhalt
          }
        }

        blog: entries(type: "post") {
          title
          id
          ... on CMS_blog_post_Entry {
            inhalt
            intermezzoText
          }
        }

        dienstleistungen: entries(type: "dienstleistungenItem") {
          title
          id
          ... on CMS_main_dienstleistungenItem_Entry {
            inhalt
          }
        }

        texte: entries(type: "texteKategorie") {
          title
          slug
          id
          ... on CMS_main_texteKategorie_Entry {
            text
          }
          children(orderBy: "erscheinungsjahr desc") {
            title
            ... on CMS_main_texteItem_Entry {
              mainImage {
                url
              }
              inhalt
              erscheinungsjahr
              isbn
              preis
              kauflich
              slug

              children {
                id
                ... on CMS_main_rezension_Entry {
                  title
                  autor
                  inhalt
                }
              }
            }
          }
        }
      }
    }
  `);

  const [filteredBlog, setFilteredBlog] = useState([]);
  const [shuffledBlog, setShuffledBlog] = useState([]);
  const [intermezzoBilder, setIntermezzoBilder] = useState([]);
  const [agendaItems, setAgendaItems] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setFilteredBlog(
      sampleSize(
        data.cms.blog.filter((item) => item.intermezzoText === true),
        6
      )
    );
    setIntermezzoBilder(
      sampleSize(data.cms.intermezzoBilder.intermezzoBilder, 6)
    );
    setAgendaItems(
      data.cms.agendaItems.filter((item) => {
        const date = new Date(item.datumUndUhrzeit);
        const now = new Date();
        return date > now;
      })
    );
    setShuffledBlog(shuffle(data.cms.blog));
    setReady(true);
  }, []);

  //lodash shuffle
  //https://lodash.com/docs/4.17.15#sampleSize

  return (
    <>
      {ready ? (
        <DefaultLayout
          ready={ready}
          agendaItems={agendaItems}
          dienstleistungenItems={data.cms.dienstleistungen}
        >
          <Intermezzo
            image={intermezzoBilder[0].url}
            quote={filteredBlog[0].inhalt}
            id="start"
          />
          {agendaItems.length > 0 ? (
            <>
              <Agenda data={agendaItems} />
              <Intermezzo
                image={intermezzoBilder[1].url}
                quote={filteredBlog[1].inhalt}
              />
            </>
          ) : (
            ""
          )}
          <Blog blogItems={shuffledBlog} />
          <Intermezzo
            image={intermezzoBilder[2].url}
            quote={filteredBlog[2].inhalt}
          />
          <Texte texteData={data.cms.texte} />
          <Intermezzo
            image={intermezzoBilder[3].url}
            quote={filteredBlog[3].inhalt}
          />
          {data.cms.dienstleistungen.length > 0 ? (
            <>
              <Dienstleistungen data={data.cms.dienstleistungen} />
              <Intermezzo
                image={intermezzoBilder[4].url}
                quote={filteredBlog[4].inhalt}
              />
            </>
          ) : (
            ""
          )}
          <Kontakt />
          <Intermezzo
            image={intermezzoBilder[5].url}
            quote={filteredBlog[5].inhalt}
          />
        </DefaultLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default IndexPage;
