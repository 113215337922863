import React from "react";
import TexteItemAuszug from "./TexteItemAuszug";
import TexteItemRezensionen from "./TexteItemRezensionen";
import TexteItemShop from "./TexteItemShop";

const TexteItem = ({ data }) => (
  <div
    id={"textItem-" + data.slug}
    className="textItem"
    itemScope=""
    itemType="http://schema.org/Book"
  >
    {data.kauflich ? <TexteItemShop data={data} /> : ""}
    <br />
    <h4 itemProp="name">
      {data.inhalt ? (
        <a className={"buttonPop auszugBody-" + data.slug} href="#">
          {data.title}
        </a>
      ) : data.children && data.children.length > 0 ? (
        <a className={"buttonPop rezensionenBody-" + data.slug} href="#">
          {data.title}
        </a>
      ) : (
        data.title
      )}
    </h4>

    {data.erscheinungsjahr ? (
      <div itemProp="copyrightYear">, {data.erscheinungsjahr}</div>
    ) : (
      ""
    )}

    {data.isbn ? (
      <div itemProp="isbn" content="979-10-95145-13-4">
        , ISBN {data.isbn}
      </div>
    ) : (
      ""
    )}

    {data.preis ? (
      <>
        <div itemProp="offers" itemScope="" itemType="http://schema.org/Offer">
          , Preis:{" "}
          <span itemProp="price" content={data.preis.toFixed(2)}>
            CHF {data.preis.toFixed(2)}
          </span>
          <meta itemProp="priceCurrency" content="CHF" />
        </div>
      </>
    ) : (
      ""
    )}
    <br />

    {data.children && data.children.length > 0 ? (
      <>
        <div className="rezensionen">
          <a className={"buttonPop rezensionenBody-" + data.slug} href="#">
            Rezensionen
          </a>
        </div>
      </>
    ) : (
      ""
    )}

    {/* toggle if not kaufbar */}
    {data.kauflich ? (
      <>
        <div className="kaufbar">
          <a className={"buttonPop shopBody-" + data.slug} href="#">
            Kaufen
          </a>
        </div>
      </>
    ) : (
      ""
    )}
    <br />
    {data.children && data.children.length > 0 ? (
      <TexteItemRezensionen data={data} />
    ) : (
      ""
    )}
    {/* {data.inhalt ? <TexteItemAuszug data={data} /> : ""} */}
    <TexteItemAuszug data={data} />
  </div>
);

export default TexteItem;
