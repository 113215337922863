import React from "react";
import TexteItemSideBar from "./TexteItemSideBar";

const TexteItemAuszug = ({ data }) => (
  <div id={"auszugBody-" + data.slug} className="auszugBody textItemBody">
    <div style={{ position: "relative" }}>
      <div className="inhaltRow">
        <div className="linkeSpalte">
          {data != "undefined" ? <TexteItemSideBar data={data} /> : ""}
        </div>

        <div className="rechteSpalte">
          <div className="rechteSpalte-inner">
            <div className="textItemTitle">{data.title}</div>
            <div className="popNav">
              <a
                className={"is-active buttonPop auszugBody-" + data.slug}
                href="#"
              >
                Auszug
              </a>
              {data.children && data.children.length > 0 ? (
                <>
                  —
                  <a
                    className={"buttonPop rezensionenBody-" + data.slug}
                    href="#"
                  >
                    Rezensionen
                  </a>
                </>
              ) : (
                ""
              )}
            </div>
            {data.inhalt ? (
              <div
                className="inhalt"
                dangerouslySetInnerHTML={{ __html: data.inhalt }}
              ></div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="close">
        <img src="/assets/gestaltungselemente/close.svg" />
      </div>
    </div>
  </div>
);

export default TexteItemAuszug;
