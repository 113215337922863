import React from "react";

const TexteItemSideBar = ({ data }) => (
  <>
    {data.mainImage && data.mainImage[0] ? (
      <div className="textItemBild">
        <img src={data.mainImage[0].url} className="mCS_img_loaded" />
      </div>
    ) : (
      ""
    )}

    <div className="textItemPreis">
      {data.preis ? (
        <>
          Preis: CHF {data.preis.toFixed(2)}
          <br />
        </>
      ) : (
        ""
      )}
      {data.kauflich ? (
        <a className={"buttonPop shopBody-" + data.slug} href="#">
          Kaufen
        </a>
      ) : (
        ""
      )}
    </div>
  </>
);

export default TexteItemSideBar;
