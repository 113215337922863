import React from "react";
import TexteKategorie from "./TexteKategorie";

const Texte = ({ texteData }) => (
  <section id="texte" className="content">
    <h2>Texte</h2>
    {texteData.map((kategorie, index) => (
      <TexteKategorie
        key={kategorie.id}
        kategorieData={kategorie}
        index={index}
      />
    ))}
  </section>
);

export default Texte;
