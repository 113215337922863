import React from "react";
import TexteItem from "./TexteItem";

const TexteKategorie = ({ kategorieData, index }) => (
  <>
    <h3
      className={
        index === 0 ? kategorieData.slug + " first" : kategorieData.title
      }
    >
      <a className={"button " + kategorieData.slug} href="#">
        {kategorieData.title}
      </a>
    </h3>
    {kategorieData.text ? <p>&nbsp;{kategorieData.text}</p> : ""}
    &nbsp;
    <div id={kategorieData.slug} className="textFolderBody">
      {kategorieData.children.map((item) => (
        <TexteItem key={item.slug} data={item} />
      ))}
    </div>
  </>
);

export default TexteKategorie;
