import React from "react";

const dateOptions = {
  day: "numeric",
  month: "short",
  year: "numeric",
};

const timeOptions = {
  hour: "2-digit",
  minute: "2-digit",
};
const Veranstaltung = ({ data }) => {
  const date = new Date(data.datumUndUhrzeit);

  return (
    <div
      className="veranstaltung"
      itemScope
      itemType="http://schema.org/LiteraryEvent"
    >
      <a
        itemProp="startDate"
        content={data.datumUndUhrzeit}
        className={"button agenda-" + data.slug}
        href="#"
      >
        <div className="datum">
          {date.toLocaleDateString("de-CH", dateOptions)},{" "}
        </div>

        <div className="uhrzeit">
          {date.toLocaleTimeString("de-CH", timeOptions)} Uhr
        </div>
      </a>
      <div itemProp="location" itemScope itemType="http://schema.org/Place">
        {data.lokal ? <div itemProp="name">, {data.lokal}, </div> : ""}

        <div
          itemProp="address"
          itemScope
          itemType="http://schema.org/PostalAddress"
        >
          <div itemProp="addressLocality">{data.ort}, </div>
        </div>

        <a itemProp="url" href={data.linkURL} target="_blank">
          {data.linkText ? data.linkText : "Link zur Veranstaltung"}
        </a>
      </div>
      <p itemProp="name">, {data.title}</p>
      {data.mitwirkende ? (
        <div itemProp="attendee">, {data.mitwirkende}</div>
      ) : (
        ""
      )}
      <div id={"agenda-" + data.slug} className="agendaBody">
        <div
          itemProp="description"
          dangerouslySetInnerHTML={{ __html: data.inhalt }}
        />
      </div>
      <br />
    </div>
  );
};

export default Veranstaltung;
