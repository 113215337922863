import React from "react";
import ShopFormular from "./ShopFormular";
import TexteItemSideBar from "./TexteItemSideBar";

const TexteItemShop = ({ data }) => (
  <div id="shopBody" className="shopBody textItemBody is-hidden">
    <div className="inhaltRow">
      <div className="linkeSpalte">
        <TexteItemSideBar data={data} />
      </div>
      <div className="rechteSpalte">
        <div className="rechteSpalte-inner">
          <div className="textItemTitle">{data.title}</div>
          <div className="popNav"></div>
          <div className="inhalt">
            <h2 className="shopBodyTitle">Kaufen</h2>
            <p className="shopBodyParagraph">Das will ich kaufen</p>
            <ShopFormular data={data} />
          </div>
        </div>
      </div>
    </div>
    <div className="close">
      <img src="/assets/gestaltungselemente/close.svg" />
    </div>
  </div>
);

export default TexteItemShop;
