import React from "react";

const BlogItem = ({ item, index }) => {
  return (
    <div className="blogItem">
      <h3 className={index === 0 ? "first" : 0}>
        <a className={"blogButton " + item.id} href={`#${item.id}`}>
          {item.title}
        </a>
      </h3>
      <div
        id={item.id}
        className="blogBody"
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{ __html: item.inhalt }}
      />
    </div>
  );
};

export default BlogItem;
