import React from "react";

const DienstleistungenItem = ({ item, index }) => {
  return (
    <div className="dienstleistungenItem">
      {/* blogItem */}
      <h3 className={index === 0 ? "first" : 0}>
        <a className={"button " + item.id} href="#">
          {item.title}
        </a>
      </h3>
      <div
        id={item.id}
        className="dienstleistungenBody"
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{ __html: item.inhalt }}
      />
    </div>
  );
};

export default DienstleistungenItem;
