import React from "react";

import Veranstaltung from "./Veranstaltung";

const Agenda = ({ data }) => {
  return (
    <section id="agenda" className="content">
      <h2>Agenda</h2>
      {data.map((item) => (
        <Veranstaltung key={item.id} data={item} />
      ))}
    </section>
  );
};

export default Agenda;
