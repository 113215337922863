import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import "normalize.css";
import "../styles/screen.css";

import Header from "../components/Header";

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
}

const DefaultLayout: React.FC = ({
  children,
  ready,
  agendaItems,
  dienstleistungenItems,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query DefaultLayoutQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => (
        <div id="wrapper">
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: "description",
                content: data.site.siteMetadata.description,
              },
            ]}
          >
            <title>Dichter Stauffer</title>
            <meta
              name="description"
              content="Der Hörspiel- und Theaterautor «Dichterstauffer», seines Zeichens auch Lyriker und Performer und notabene gewichtiges Mitglied der Spoken Word-Formation «​ ..."
            />
          </Helmet>
          <Header
            agendaItems={agendaItems}
            dienstleistungenItems={dienstleistungenItems}
          />
          {children}
          <div id="frame-top"></div>
          <div id="frame-right"></div>
          <div id="frame-bottom"></div>
          <div id="frame-left"></div>
          {ready ? (
            <Helmet>
              <script src="/js/combined.js" type="text/javascript"></script>
            </Helmet>
          ) : (
            ""
          )}
        </div>
      )}
    />
  );
};

export default DefaultLayout;
