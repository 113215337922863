import React from "react";

const Rezension = ({ data }) => (
  <>
    <div className="rezensionTitle">{data.title}</div>
    <div dangerouslySetInnerHTML={{ __html: data.inhalt }}></div>
    <div className="rezensionAuthor">{data.autor}</div>
    <br />
  </>
);

export default Rezension;
