import React, { useState } from "react";

const ShopFormular = ({ data }) => {
  const [isSent, setIsSent] = useState(false);

  const formSubmit = (e) => {
    e.preventDefault();
    const form = document.querySelector(".shopformular-" + data.slug);
    const formData = new URLSearchParams(new FormData(form));
    fetch("https://formmail.goeast.ch/codezone/goeastform.cfm", {
      method: "post",
      body: formData,
    });
    setIsSent(true);
  };
  return isSent ? (
    <div>Vielen Dank für Ihre Bestellung</div>
  ) : (
    <>
      <form
        className={"shopformular mura-form-builder shopformular-" + data.slug}
        method="post"
        acceptCharset="utf-8"
        onSubmit={formSubmit}
      >
        <input type="hidden" value="dichterstauffer_ich" name="recipient" />
        <input type="hidden" name="titel" value={data.title} readOnly />{" "}
        <input type="hidden" name="isbn" value={data.iban} readOnly />{" "}
        <input
          type="hidden"
          name="preis"
          value={data.preis.toFixed(2)}
          readOnly
        />{" "}
        <div className="mura-form-textfield req ">
          <label htmlFor="vorname">
            Vorname <ins>Required</ins>
          </label>
          <input type="text" name="vorname" required />
        </div>
        <div className="mura-form-textfield req ">
          <label htmlFor="name">
            Name <ins>Required</ins>
          </label>
          <input type="text" name="name" required />
        </div>
        <div className="mura-form-textfield req ">
          <label htmlFor="email">
            E-Mail <ins>Required</ins>
          </label>
          <input type="text" name="email" required />
        </div>
        <div className="mura-form-textarea req">
          <label htmlFor="adresse">
            Adresse <ins>Required</ins>
          </label>
          <textarea rows={5} name="adresse" required></textarea>
        </div>
        <input
          type="hidden"
          value="Dichter Stauffer Bestellformular"
          name="subject"
        />
        <div>
          <br />
          <input type="submit" value="senden" />
        </div>
      </form>
    </>
  );
};

export default ShopFormular;
