import React from "react";

import BlogItem from "./BlogItem";

const Blog = ({ blogItems }) => (
  <section id="blog" className="content">
    <h2>Blog</h2>
    {blogItems.map((item, index) => (
      <BlogItem key={item.id} index={index} item={item} />
    ))}
  </section>
);

export default Blog;
