import React from "react";
import TexteItemSideBar from "./TexteItemSideBar";
import Rezension from "./Rezension";

const TexteItemRezensionen = ({ data }) => (
  <div
    id={"rezensionenBody-" + data.slug}
    className="rezensionenBody textItemBody"
  >
    <div className="inhaltRow">
      <div className="linkeSpalte">
        <TexteItemSideBar data={data} />
      </div>

      <div className="rechteSpalte">
        <div className="rechteSpalte-inner">
          <div className="textItemTitle">{data.title}</div>
          <div className="popNav">
            {data.inhalt ? (
              <>
                <a className={"buttonPop auszugBody-" + data.slug} href="#">
                  Auszug
                </a>
                —
              </>
            ) : (
              ""
            )}
            <a
              className={"is-active buttonPop rezensionenBody-" + data.slug}
              href="#"
            >
              Rezensionen
            </a>
          </div>
          <div className="inhalt">
            {data.children.map((item) => (
              <Rezension key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="close">
      <img src="/assets/gestaltungselemente/close.svg" />
    </div>
  </div>
);
export default TexteItemRezensionen;
