import React from "react";

import DienstleistungenItem from "./DienstleistungenItem";

const Dienstleistungen = ({ data }) => (
  <section id="dienstleistungen" className="content">
    {/* blog */}
    <h2>Dienstleistungen</h2>
    {data.map((item, index) => (
      <DienstleistungenItem key={item.id} index={index} item={item} />
    ))}
  </section>
);

export default Dienstleistungen;
